import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Alert,
  Glyphicon,
  Grid,
  Col,
  Row
} from "react-bootstrap";
import ReactQuill from 'react-quill';
import _get from "lodash/get";
import _pick from "lodash/pick";
import FieldGroup from "./FieldGroup";
import FieldGroupSelect from "./FieldGroupSelect";
import Rules from "./Rules";
import PostSteps from "./PostSteps";
import {
  AD_TYPES,
  INITIAL_STATE,
  ETHNICITY_OPTIONS,
  OUTCALLS_OPTIONS,
  INCALL_OPTIONS,
  BUSINESS_OPTIONS,
  PAYMENT_TIERS,
  PAYMENT_TIERS_FEATURED,
  SITES,
  isPaidOption,
  isFreeOption
} from "./PostFormDef";
import { sendEvent } from "../../lib/utils";
import * as Views from "./PostViewsDef";
import PhotoUpload from './PhotoUpload';
import Spinner from "../Spinner/Spinner";
import FieldLocation from "../FieldLocation/FieldLocation";
import AdPreview from "../AdPreview/AdPreview";
import PayForm from "../PayForm/PayForm";
import AdPhotos from "./AdPhotosContainer";
import 'react-quill/dist/quill.snow.css';

const styles = {
  editor: {
    height: 250,
    marginBottom: 60,
  },
}

const getPriceOptions = options => {
  return options.map(el => (
    <li key={el.amount} value={el.amount}>
      <b>
        <span className="amount">€ {el.amount}</span> for {el.label}
      </b>
    </li>
  ));
};

const getOptionHeading = (adType, hostname) => {
  switch (adType) {
    case AD_TYPES.FREE_VERIFIED: {
      return (
        <div className="alert alert-success">
          You have selected a <strong>verified free</strong> option. You will be presented
          with verification instructions after validating your phone number.
        </div>
      );
    }

    case AD_TYPES.FREE: {
      return (
        <div className="alert alert-success">
          Your ad type selection is <strong>free with link exchange</strong>.
          You will need to have a page that links to our site in order to post
          your ad. Valid backlink is required to have your ads approved! Please
          note that we only allow one ad per link that you sent to us; you are
          not able to use the same link for multiple ads.
        </div>
      );
    }

    default: {
      const typeStr =
        adType === AD_TYPES.FEATURED ? "FEATURED PAID" : "VIP PAID";
      return (
        <div className="disclaimer">
          You have selected {typeStr} ad. You need BITCOIN to complete posting this type of ad.
        </div>
      );
    }
  }
};

class PostForm extends Component {
  constructor(props) {
    super(props);
    const { isAuthenticated, postId } = props;

    this.state = {
      postId: postId || "",
      type: null,
      view: Views.POST_RULES,
      fields: INITIAL_STATE,
      bufferPhotos: [],
      valid: {
        phone: false,
        email: isAuthenticated,
        title: false,
        description: false,
        age: true,
        name: false,
      },
      validClss: {
        phone: null,
        email: null,
        title: null,
        description: null,
        age: null,
        backlink: null,
        name: null
      },
      isValidForm: false,
      backlinkValidated: false, // only used with FREE ads
      invalidFields: [],
      isRenew: !!this.props.renew,
      tier: SITES.TIER1
    };
  }

  componentDidMount() {
    const { postId, fetchPost } = this.props;
    if (postId) {
      fetchPost(postId);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.validation.backlink !== prevProps.validation.backlink) {
      this.setState({ backlinkValidated: this.props.validation.backlink });
    }

    // when navigating back to form, hydrate state with post props
    if (this.props.view === Views.POST_NEW && prevProps.view !== this.props.view && this.props.post) {
      const data = _pick(this.props.post, 
        ['name', 'business', 'phone', 'email', 'age', 'ethnicity', 'incalls', 'outcalls', 'title', 'description', 
         'post_id', 'country', 'country_full', 'city', 'city_full', 'type']);
      console.log("hydrating post", data);

      this.setState({
        fields: data
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.renew) {
      const { setView, enableSteps } = this.props;
      this.setState({
        isRenew: true
      });
      enableSteps([Views.POST_RULES, Views.SELECT_TYPE, Views.POST_NEW, Views.PHOTO_UPLOAD])
      setView(Views.PAYMENT);
    }
  }

  onChange = (e) => {
    const newState = { 
      fields: { ...this.state.fields }
    };
    newState.fields[e.target.name] = e.target.value;
    this.setState(newState);
  };

  handleDescChange = (value) => {
    const newState = { 
      fields: { ...this.state.fields, description: value },
      valid: { ...this.state.valid, description: true },
      invalidFields: [ ...this.state.invalidFields ].filter(el => el !== 'description')
    };

    this.setState(newState);
  }

  onChangeLocation = location => {
    let newState = { fields: Object.assign({}, this.state.fields) };

    newState.fields.city = location.city;
    newState.fields.country = location.country;
    newState.fields.region = location.region;
    newState.fields.city_full = location.cityFull;
    newState.fields.country_full = location.countryFull;

    // determine tier for this location
    // popular cities are higher tiers (more expensive)
    let tier = SITES.TIER1;
    if (PAYMENT_TIERS.TIER2.CITIES.includes(newState.fields.city)) {
      tier = SITES.TIER2;
    }
    newState.tier = tier;
    this.setState(newState);
  };

  onValidate = (isValid, name) => {
    // set valid flags
    let valid = Object.assign({}, this.state.valid);
    valid[name] = isValid;

    // set valid classes
    let validClss = Object.assign({}, this.state.validClss);
    validClss[name] = isValid ? null : "error";

    this.setState({ valid, validClss });
  };

  onTypeSelection = e => {
    e.preventDefault();
    const { setView, enableSteps } = this.props;
    const { type } = this.state;

    sendEvent(`type-select-${type}`, 'AD-POST');

    setView(Views.POST_NEW);
    enableSteps([Views.SELECT_TYPE]);
  };

  /**
   * @deprecated
   * Check if same phone was used previously
   */
  onBlurPhone = e => {
    e.preventDefault();
    if (this.props.validateAd) {
      this.props.validateAd(this.state.fields, e.target.name);
    }
  };

  /**
   * Check if backliknk exists in other site
   */
  onBlurBacklink = e => {
    e.preventDefault();
    if (this.props.validateAd) {
      this.props.validateAd(this.state.fields, e.target.name);
    }
  };

  onClickCode = e => {
    e.target.select();
  };

  onSubmit = e => {
    const { setView, enableSteps, createPost, updatePost, token } = this.props;
    e.preventDefault();

    // validate form
    const fieldNames = Object.keys(this.state.valid);
    let isValidForm = true;
    let invalidFields = [];
    for (let fieldName of fieldNames) {
      isValidForm = isValidForm && this.state.valid[fieldName];
      if (!this.state.valid[fieldName]) {
        invalidFields.push(fieldName);
      }
    }

    // if form is valid submit as unapproved
    if (isValidForm) {
      const { fields, type } = this.state;
      const { post_id } = fields;
      const post = { ...fields, type };

      // post recort was never created, create this
      if(!post_id) {
        createPost(post).then(resp => {
          sendEvent('post-new-ad', 'AD-POST');
        }).finally(() => {
          setView(Views.PHOTO_UPLOAD);
          enableSteps([Views.POST_NEW, Views.PHOTO_UPLOAD]);
        });
      }
      // we have a post ID, so we just need to continue flow
      else {
        setView(Views.PHOTO_UPLOAD);
        enableSteps([Views.POST_NEW, Views.PHOTO_UPLOAD]);

        updatePost(post, token);
      }

    } else {
      this.setState({ invalidFields });
    }
  };

  validationMessage = () => {
    if (this.state.invalidFields.length > 0) {
      return (
        <Alert bsStyle="danger">
          <b>Invalid form data found!</b> Please check these fields:
          <b> {this.state.invalidFields.join(", ")}</b>
        </Alert>
      );
    }
  };

  // Get tier based on the city. Larger cities are more expensive
  // for posting / renewals. If not found return default tier
  getTier() {
    const { SITE_NAME } = this.props.env;
    const isTS = SITE_NAME === "neogirlz.com" || SITE_NAME === "t-escorts.com";
    if (this.props.post) {
      const city = this.props.post.city;
      if (city && PAYMENT_TIERS.TIER2.CITIES.includes(city) && !isTS) {
        return SITES.TIER2;
      }
    }

    return SITES.TIER1;
  }

  uploadPhotos = (e) => {
    const { uploadPhotos, enableSteps, setView, post } = this.props;
    const { bufferPhotos } = this.state;
    const self = this;
    const post_id = _get(post, 'post_id', false);
    if(post_id) {

      // if there is dirty buffer upload photos
      if(bufferPhotos.length > 0) {
        uploadPhotos(post_id, bufferPhotos)
          .then(res => {
            enableSteps([Views.PHOTO_UPLOAD, Views.PAYMENT]);
            setView(Views.PAYMENT);
            self.setState({ bufferPhotos: [] });
        });
      }
      // if no dirty buffer then just navigate to payment
      else {
        setView(Views.PAYMENT);
      }
    }
  }

  renderForm() {
    const { steps, view, setView } = this.props;
    const { type } = this.state;
    return (
      <div className="main-container">
        <h1>Post an Ad</h1>
        <PostSteps type={type} steps={steps} view={view} setView={setView} />
        <h4>
          Posting on {this.state.fields.city_full}, {this.state.fields.country_full}
        </h4>
        {this.renderAdTypeHeading()}
        <form onSubmit={this.onSubmit}>
          {this.renderBacklinkInput()}
          {this.renderFormFields()}
        </form>
      </div>
    );
  }

  renderPostAdButton() {
    let isValid = this._isValidServer();
    return (
      <Button disabled={!isValid} className="btn btn-info btn-wider" type="submit">
        Next Step
      </Button>
    );
  }

  renderNoBacklinkMessage() {
    if (this.props.validation.backlink === false) {
      return (
        <Alert bsStyle="danger">
          We could not find a backlink on URL you provided or we don't allow
          links from this website. Please make sure you have a link to us first
          before starting to write your ad copy!
        </Alert>
      );
    }
  }

  renderBacklinkInput() {
    const { type } = this.state;
    if (isPaidOption(type) || type === AD_TYPES.FREE_VERIFIED) {
      return;
    } else if ( this.state.type === AD_TYPES.FREE &&
      this.state.backlinkValidated) {
      return;
    }

    return (
      <div>
        <FieldGroup
          id="backlink"
          name="backlink"
          type="url"
          required
          label="Your Link to Us (required)"
          placeholder="Enter URL where you linked to us (eg. http://yourwebsite.com/page.html)"
          help="Please note that this is not our URL and it is for FREE ads only. It is your personal page, blog or website with our link in it! If you link to us your ad will be featured in our website. If our link is missing your ad will not be featured!"
          onChange={this.onChange}
          onBlur={this.onBlurBacklink}
          value={this.state.backlink}
          onValidate={this.onValidate}
          clss={this.state.validClss.backlink}
        />
        {this.renderNoBacklinkMessage()}

        <Button className="btn btn-info mar-bottom-lg btn-wider" type="button">
          Next Step
        </Button>
      </div>
    );
  }

  renderFormFields() {
    const { isAuthenticated, currentUser } = this.props;
    const { fields } = this.state;
    if (this.state.type === AD_TYPES.FREE && !this.state.backlinkValidated) {
      return;
    }

    return (
      <div className="formFields">
        <Row>
          <Col xs={12} md={6}>
            <FieldGroup
              id="inputName"
              name="name"
              type="text"
              label="Name (required)"
              help="Please choose a name or a nickname that you are comfortable sharing with others."
              placeholder="Your name or nickname"
              onChange={this.onChange}
              value={fields.name}
              maxLength={"45"}
              required={"required"}
              onValidate={this.onValidate}
              clss={this.state.validClss.name}
            />
          </Col>

          <Col xs={12} md={6}>
            <FieldGroupSelect
              id="inputBusiness"
              name="business"
              label="Type of Business (optional)"
              onChange={this.onChange}
              value={fields.business}
              options={BUSINESS_OPTIONS}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <FieldGroup
              id="inputPhone"
              name="phone"
              type="phone"
              label="Phone number"
              help="Valid phone number is required. Your phone number will be used for verification! This is also the phone number that will be displayed in your ad."
              placeholder="Enter your phone number with the area code"
              onChange={this.onChange}
              onValidate={this.onValidate}
              value={fields.phone}
              required={"required"}
              maxLength={"32"}
              clss={this.state.validClss.phone}
              showRemaining={false}
            />
          </Col>
          <Col xs={12} md={6}>
            <FieldGroup
              id="inputEmail"
              name="email"
              type="email"
              label="Email address (required)"
              placeholder="Enter your email address"
              help="Email is required to identify you. We will not display this in your profile, but we will send you relevant information about your ad (eg. email verification, or editing instructions)"
              onChange={this.onChange}
              value={isAuthenticated ? currentUser.email : fields.email}
              required={"required"}
              onValidate={this.onValidate}
              clss={this.state.validClss.email}
              readOnly={isAuthenticated}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={6}>
            <FieldGroup
              id="inputAge"
              name="age"
              type="text"
              label="Age (required)"
              placeholder="Enter your age (eg. 21)"
              onChange={this.onChange}
              value={String(fields.age)}
              maxLength={"2"}
              numeric={1}
              required={"required"}
              onValidate={this.onValidate}
              clss={this.state.validClss.age}
            />
          </Col>
          <Col xs={12} md={6}>
            <FieldGroupSelect
              id="inputEthnicity"
              name="ethnicity"
              label="Ethnicity (optional)"
              placeholder="Select your ethnicity"
              onChange={this.onChange}
              value={fields.ethnicity}
              options={ETHNICITY_OPTIONS}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={6}>
            <FieldGroupSelect
              id="inputIncalls"
              name="incalls"
              label="Incalls (optional)"
              onChange={this.onChange}
              value={fields.incalls}
              options={INCALL_OPTIONS}
            />
          </Col>
          <Col xs={12} md={6}>
            <FieldGroupSelect
              id="inputOutcalls"
              name="outcalls"
              label="Outcalls (optional)"
              onChange={this.onChange}
              value={fields.outcalls}
              options={OUTCALLS_OPTIONS}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <FieldGroup
              id="inputTitle"
              name="title"
              type="text"
              label="Headline of your ad (required)"
              placeholder="Enter the headline"
              onChange={this.onChange}
              value={fields.title}
              required={"required"}
              onValidate={this.onValidate}
              clss={this.state.validClss.title}
              help="This is the heading of your profile. Keywords found in here till carry more weight than in your description."
              maxLength={"80"}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <label className="control-label">Your ad (required)</label>
            <ReactQuill 
              style={styles.editor} 
              name="description"
              theme="snow" 
              value={fields.description} 
              onChange={this.handleDescChange}
              placeholder="This is the body of your ad. Include all the details in here. Make sure your ad is descriptive and has all the details your viewers need to see."
            />
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            {this.validationMessage()}

            <p>{this.renderPostAdButton()}</p>
            <p></p>
            <div className="alert alert-warning">
              <Glyphicon glyph="info-sign" /> Once your ad is submitted it will
              be reviewed for illegal or inappropriate content. Once it's
              approved it will be visible to everyone. Priority will be given to
              VIP Ads.
              <br />
              <br />
              <b>
                <Glyphicon glyph="exclamation-sign" /> Important:
              </b> Once the ad has been posted you will receive a welcome email.
              <b> Please save this email</b> if you want to <b>update</b> or
              <b> remove</b> your ad.
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  renderAdTypeHeading() {
    const { SITE_NAME } = this.props.env;
    const adType = this.state.type;
    const headingContent = getOptionHeading(adType, SITE_NAME);
    return (
      <div className="amount mar-top-lg mar-bottom-lg">{headingContent}</div>
    );
  }

  renderFreeOption() {
    const { SITE_NAME } = this.props.env;
    return (
      <div className="option option-free">
        <Grid bsClass="no-padding">
          <Row className="option-row">
            <Col xs={12} sm={8}>
              <h4>
                <input
                  type="radio"
                  name="type"
                  id="type-free"
                  onClick={() => {
                    this.setState({ type: AD_TYPES.FREE });
                  }}
                />
                &nbsp;
                <label className="form-check-label" htmlFor="type-free">
                  FREE Ads with Link to Us
                </label>
                <div className="label-sub">Link to {SITE_NAME} required</div>
              </h4>
              <p>It is FREE to post an ad at {SITE_NAME}. However, we require that you
              link to us from your website. Include this HTML in your pages and do
              this before you try posting the ad:
              <textarea
                className="code-block"
                onClick={this.onClickCode}
                readOnly
                value={this._getFreeAdLink()}
              />
              We verify this before we publish your ad!
              </p>
              <p>
              <i>NOTE:</i> While we make best effort to publish all free ads we cannot
              guarantee that your ad will be published. We only publish ads if links
              are from valid and complete websites. Links from SPAM websites or pages
              will not be approved.
              </p>
            </Col>
            <Col xs={12} sm={4} align="center" className="icon-container">
              <img className="option-img" align="center"
                src="/img/free-sample.jpg" 
                alt="verified ad" />
            </Col>
          </Row>
        </Grid>      
      </div>
    );
  }

  renderFreeVerifiedOption() {
    return (
      <div className="option option-free-verified">
        <Grid bsClass="no-padding">
          <Row className="option-row">
            <Col xs={12} sm={8}>
              <h4>
                <input
                  type="radio"
                  name="type"
                  id="type-free-verified"
                  onClick={() => {
                    this.setState({ type: AD_TYPES.FREE_VERIFIED });
                  }}
                />
                &nbsp;
                <label
                  className="form-check-label"
                  htmlFor="type-free-verified"
                >
                  FREE Verified Ads
                </label>
                <div className="label-sub">Verification is required</div>
              </h4>

              <p>
                Our users love verified profiles and this is the best way to show them you
                are a real person. Note that all free verified ads are reviewed and must pass 
                verification before they get published. Please be prepared to take a photo
                of yourself to prove that pictures are yours!
              </p>
            </Col>
            <Col xs={12} sm={4} align="center" className="icon-container">
              <img className="option-img"
                src="/img/free-verified-sample.jpg" 
                alt="verified free ad" />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }

  renderVipOption() {
    const { tier } = this.state;
    const selectOptions = getPriceOptions(PAYMENT_TIERS[tier].OPTIONS);

    return (
      <div className="option option-vip">
        <Grid bsClass="no-padding">
          <Row className="option-row">
            <Col xs={12} sm={8}>
              <h4>
                <input
                  type="radio"
                  name="type"
                  id="type-paid"
                  onClick={() => {
                    this.setState({ type: AD_TYPES.PAID });
                  }}
                />
                <label className="form-check-label" htmlFor="type-paid">
                  &nbsp; VIP Paid Ad
                </label>
                <div className="label-sub">Bitcoin Required</div>
              </h4>
              VIP Ads will always appear on
              <b>
                <i> top of all FREE ads </i>
              </b> for a chosen city. VIP ads guarantee top positions and get the most
              views relative to other ad types.

              <ul>{selectOptions}</ul>
            </Col>
            <Col xs={12} sm={4} align="center" className="icon-container">
              <img className="option-img"
                src="/img/vip.png" 
                alt="vip ad" />
            </Col>
          </Row>
        </Grid>    
      </div>
    );
  }

  renderFeaturedOption() {
    const { tier } = this.state;
    const selectOptions = getPriceOptions(PAYMENT_TIERS_FEATURED[tier].OPTIONS);

    return (
      <div className="option option-featured">
        <Grid bsClass="no-padding">
          <Row className="option-row">
            <Col xs={12} sm={8}>
              <h4>
                <input
                  type="radio"
                  name="type"
                  id="type-featured"
                  onClick={() => {
                    this.setState({ type: AD_TYPES.FEATURED });
                  }}
                />
                <label className="form-check-label" htmlFor="type-featured">
                  &nbsp; Featured Ad
                </label>
                <div className="label-sub">Bitcoin Required</div>
              </h4>
              Featured Ads
              <b>
                <i> always appear on top </i>
              </b>
              and stand out from all other ads. This is the premium tier that steals
              most of the views on a city. This is the type of ad you need if you
              never want to get bumped down by other ads.
              <ul>{selectOptions}</ul>
            </Col>
            <Col xs={12} sm={4} align="center" className="icon-container">
              <img className="option-img"
                src="/img/crown3.png" 
                alt="featured ad" />
            </Col>
          </Row>
        </Grid>    
      </div>
    );
  }

  renderSelectType() {
    const { SITE_EMAIL } = this.props.env;
    const { steps, view, setView } = this.props;
    const { type, fields } = this.state;
    const isDisabled = !type || !fields.city;
    let infoText = false;
    if (!fields.city) {
      infoText = "To continue please select a city first!";
    } else if (!type) {
      infoText = "To continue please select type of ad you want to post!";
    }

    return (
      <div className="main-container">
        <h1>Post an Ad</h1>
        <PostSteps type={type} steps={steps} view={view} setView={setView} />
        <div className="post-rules">
          <form>
            <h3>1. Choose Your Location</h3>
            <FieldLocation
              id="city"
              name="city"
              country={fields.country}
              value={fields.city}
              city={fields.city}
              onChange={this.onChangeLocation}
              helpEmail={SITE_EMAIL}
              required
              autoBlur
            />

            <h3>2. Select Ad Type</h3>
            
            {this.renderVipOption()}
            {this.renderFreeOption()}
            {this.renderFreeVerifiedOption()}
            {/* {this.renderFeaturedOption()} */}

            <Button
              className="btn btn-info btn-wider"
              onClick={this.onTypeSelection}
              type="button"
              disabled={isDisabled}
            >
              Continue
            </Button>
            {infoText && <p className="helpText">{infoText}</p>}
          </form>
        </div>
      </div>
    );
  }

  renderRules() {
    const { type } = this.state;
    const { steps, view, setView } = this.props;
    return (
      <div className="main-container">
        <h1>Post an Ad</h1>
        <PostSteps type={type} steps={steps} view={view} setView={setView} />
        <div className="post-rules">
          <Rules {...this.props} />
        </div>
      </div>
    );
  }

  /**
   * @deprecated
   */
  renderPhoneVerify() {
    const { steps, view, setView } = this.props;
    const { type } = this.state;
    return (
      <div className="main-container">
        <h1>Post an Ad</h1>
        <PostSteps type={type} steps={steps} view={view} setView={setView} />
        <form onSubmit={this.onSubmit}>
          <FieldGroup
            id="verification"
            name="verification"
            onChange={this.onChange}
            value={this.state.verification}
            type="text"
            label="Phone verification code (required)"
            placeholder="Code verifiation sent to your phone"
            help="We sent you a SMS with a code to verify your number. Please enter here to complete ad submission!"
          />

          <Alert bsStyle="success">{this.props.verifySent.message}</Alert>

          <Button className="btn btn-info" type="submit">
            Verify Phone
          </Button>
        </form>
      </div>
    );
  }

  renderFreeSuccessMessage(type) {
    switch (type) {
      case AD_TYPES.FREE_VERIFIED: {
        return (
          <div>
            <div className="alert alert-success alert-large">
              <p>
                <strong>Your Verified FREE Ad is Submitted!</strong>
              </p>
              <p>
                We will be looking at your ad shortly. You can expect this review to 
                take up to 48 hours. Once approved your ad will be live immediately.
              </p>
            </div>
          </div>

        );
      }

      default: {
        return (
          <div className="alert alert-success alert-large">
            <p>
              <strong>Your FREE ad submission is complete!</strong>
            </p>
            <p>
              We will review it and publish as soon as possible! Please
              understand that we have a high volume of free ads coming in. Give
              us at least 48 hours before emailing us.
            </p>
          </div>
        );
      }
    }
  }

  renderPhotos() {
    const self = this;
    const { steps, view, setView, post } = this.props;
    const { type, bufferPhotos } = this.state;
    const photos = _get(post, 'photos', []);
    return (
      <div className="main-container">
        <h1>Upload Photos</h1>
        <PostSteps type={type} steps={steps} view={view} setView={setView} />

        <PhotoUpload enabled={true} onChange={photos => self.setState({ bufferPhotos: photos })} />
        <AdPhotos />

        <Button 
          disabled={bufferPhotos.length < 1 && photos.length < 1} 
          style={{ marginTop: 20 }} 
          className="btn btn-info btn-wider" 
          type="button"
          onClick={this.uploadPhotos}
        >
          Done with Photos
        </Button>
      </div>
    );
  }

  /**
   * Payment Screen: Render preview of the ad
   */
  renderPreview() {
    const { address, post } = this.props;
    const postId = _get(post, "post_id", false);

    if (post && !address) {
      return <AdPreview {...post} key={postId} />;
    }
  }

  /**
   * Payment Screen
   */
  renderPayment() {
    const title = this.state.isRenew ? "Renew Your Ad" : "Almost there...";
    const { steps, env, loading, post, view, setView, address } = this.props;
    const { type } = this.state;
    return (
      <div className="ad-complete">
        <h1>{title}</h1>
        <PostSteps type={type} steps={steps} view={view} setView={setView} />
        {isPaidOption(type) && (
          <div>
            <div>
              <PayForm
                type={type}
                env={env}
                post={post}
                isRenew={false}
                isVisible={!loading}
                address={address}
              />
            </div>
            <div>{this.renderPreview()}</div>
          </div>
        )}

        {isFreeOption(type) && (
          <div>
            <div>
              <p>
                If you are happy with your ad you can finalize it now. We are working hard to approve all ads, 
                but approval for free ads may take a bit longer. Please give this at least 48 hours before contacting us.
              </p>
              <Button 
                className="btn btn-primary btn-lg" 
                style={{ padding: 20, marginBottom: 30, minWidth: 200}}
                onClick={() => setView(Views.POST_SUCCESS)}
              >
                Publish Ad
              </Button>
            </div>   
            <div>{this.renderPreview()}</div>
          </div>
        )}
      </div>
    );
  }

  renderCompleteError() {
    const { SITE_EMAIL } = this.props.env;
    return (
      <div>
        <h1>Oops... There was an issue!</h1>
        <div className="alert alert-danger alert-large">
          <p>
            <strong>We could not finish posting your ad!</strong>
          </p>
          <p>
            There are few things that could be wrong:
            <ul>
              <li>
                If you provided a backlink for a free ad and we can't find it in
                your page then we can't add your ad!
              </li>
              <li>
                Our system is temporarily unavailable. Please try again in a few
                minutes and do contact us at <i>{SITE_EMAIL}</i> if issue
                persists.
              </li>
            </ul>
          </p>
        </div>
      </div>
    );
  }

  render() {
    const { view, loading } = this.props;
    const { type } = this.state;

    if (loading) {
      return <Spinner />;
    }

    if (view === Views.SELECT_TYPE) {
      return this.renderSelectType();
    }

    if (view === Views.POST_NEW) {
      return this.renderForm();
    }

    if (view === Views.PHOTO_UPLOAD) {
      return this.renderPhotos();
    }

    if (view === Views.POST_SUCCESS && isFreeOption(type)) {
      return this.renderFreeSuccessMessage(type);
    }

    if (view === Views.PAYMENT || this.state.isRenew) {
      return this.renderPayment();
    }

    if (view === Views.POST_ERROR) {
      return this.renderCompleteError();
    }

    return this.renderRules();
  }

  /**
   * Checks if server site validation has passed.
   * Currently checking phone and backlinks if available.
   */
  _isValidServer() {
    let isValid = true;
    let keys = Object.keys(this.props.validation);
    for (let k of keys) {
      if (this.props.validation[k] !== null) {
        isValid = isValid && this.props.validation[k];
      }
    }

    return isValid;
  }

  /**
   * Get suggested backlink for posters
   */
  _getFreeAdLink() {
    const { SITE_NAME, SITE_DESCRIPTION } = this.props.env;
    // let prefix = this.state.fields.city ? this.state.fields.city + '.' : '';
    return `<a href="https://${SITE_NAME}">${SITE_NAME} - ${SITE_DESCRIPTION}</a>`;
  }
}

PostForm.propTypes = {
  createPost: PropTypes.func.isRequired,
  createEvent: PropTypes.func.isRequired,
  uploadPhotos: PropTypes.func.isRequired,
  validateAd: PropTypes.func.isRequired,
  fetchPost: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired,
  env: PropTypes.object,
  isRenew: PropTypes.bool,
  postId: PropTypes.string,
  token: PropTypes.string,
};

PostForm.defaultProps = {
  env: {}
};

export default PostForm;
