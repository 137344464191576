import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import _get from 'lodash/get';
import BitcoinQR from './BitcoinQR';
import { TX_STATUS, sendEvent } from '../../lib/utils';

const POLL_INTERVAL = 15000;

class BitcoinReceive extends Component {
  constructor(props) {
    super(props);
    this.statusInterval = null;
  }

  componentDidMount() {
    const { address } = this.props;
    if(address && typeof(address) === 'string') {
      this.startPolling();
    }
  }

  componentDidUpdate(prevProps) {
    const { address, payment, updatePaymentStatus, postId } = this.props;
    const newStatus = _get(payment, 'status');
    const prevStatus = _get(prevProps, ['payment', 'status']);
    if(address && !prevProps.address) {
      this.startPolling();
    }

    if(newStatus === TX_STATUS.COMPLETE && this.statusInterval) {
      this.stopPolling();
    }

    if(newStatus !== prevStatus && newStatus !== TX_STATUS.PENDING && address) {
      updatePaymentStatus(address, postId, newStatus);

      if(newStatus === TX_STATUS.PROCESSING || newStatus === TX_STATUS.COMPLETE) {
        sendEvent('payment_complete', 'AD-POST');
      }
    }
  }

  componentWillUnmount() {
    this.stopPolling();
  }

  revealAddress() {
    const { generateAddress, postId, amount, product } = this.props;
    generateAddress(postId, amount, product);
  }

  startPolling() {
    const { checkPaymentStatus, address, postId } = this.props;
    this.statusInterval = setInterval(() => {
      checkPaymentStatus(address, postId);
    }, POLL_INTERVAL);

    // hack. we dont want this set globally
    window.pollInterval = this.statusInterval;
  }

  stopPolling() {
    if(this.statusInterval) {
      clearInterval(this.statusInterval);
      this.statusInterval = null;
    }
  }

  render() {
    const { postId, amount, address, payment } = this.props;
    
    return (
      <div>
        {!address && 
        <Button 
          className="btn btn-primary btn-lg"
          onClick={() => this.revealAddress(postId, amount)}
          style={{ marginBottom: 30, padding: 25 }}>Pay with Bitcoin <span style={{ fontWeight: 'bold'}}>₿</span></Button>}

        {address && address.length > 0 && 
        <BitcoinQR payment={payment} />}
      </div>
    );
  }
}

BitcoinReceive.propTypes = {
  checkPaymentStatus: PropTypes.func.isRequired,
  updatePaymentStatus: PropTypes.func.isRequired,
  generateAddress: PropTypes.func.isRequired,
  postId: PropTypes.string,
  amount: PropTypes.number,
  address: PropTypes.string,
  product: PropTypes.string,
  payment: PropTypes.object
};

export default BitcoinReceive;
